
.modal {
  max-width: 50%;
  .modal-title {
    background-color: #f6f7f8;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    padding: 10px 20px;
  }
}


@media (max-width: 1200px) {
  .modal {
    max-width: 100%;
  }
}