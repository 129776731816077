@import "/assets/sass/utils/variables";

.CalendarDay__selected_span {
  background: rgba($main-color-rgb,60%); //background
  color: white; //text
  border: 1px solid rgba($main-color-rgb,20%); //default styles include a border
  &:hover {
    background: rgba($main-color-rgb,75%); //background
    color: white; //text
    border: 1px solid rgba($main-color-rgb,20%); //default styles include a border
  }
}

// Will edit selected date or the endpoints of a range of dates
.CalendarDay__selected {
  background: rgba($main-color-rgb, 70%);
  color: white;
  border: 1px solid rgba($main-color-rgb,40%);
  &:hover {
    background: rgba($main-color-rgb, 90%);
    color: white;
    border: 1px solid rgba($main-color-rgb,40%);
  }
}

// Will edit when the second date (end date) in a range of dates
// is not yet selected. Edits the dates between your mouse and said date
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: rgba($main-color-rgb,30%);
  border: 1px solid rgba($main-color-rgb,10%);

}

.CalendarDay__blocked {
  &_calendar, &_calendar:hover, &_calendar:active {
      background: rgba(202, 204, 205, 0.20);
      border: 1px solid rgba(202, 204, 205, 0.4);
      color: #82888a;
  }
}

.CalendarDay__blocked_calendar.CalendarDay__selected_span {
  background: rgba($main-color-rgb, 30%); //background
  color: white; //text
  border: 1px solid rgba($main-color-rgb, 20%); //default styles include a border

}