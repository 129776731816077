
.input-wrapper {
  position: relative;
  .input-icon {
    position: absolute;
    left: 0;
    top: 3px;
    transform: translate(10px, 50%);
  }
}

.place-input {
  padding-left: 40px;
}

.date-input {
  text-align: right;
}

.time-input {
  text-align: right;
}

.outline-input:global(.MuiTextField-root) {
  legend {
    float: inherit;
  }
}